<template>
  <div class="print-box">
    <div class="print-n-box">
      <div id="printBox">
        <div style="font-size: 24px;font-weight: 600;border-bottom: 1px solid #ccc;padding-bottom: 15px;line-height: 60px;">
          <span v-if="type === 'pickList'">{{ $t('key1000180') }}</span>
          <span>{{ detailInfo.pickingGoodsNo }}</span>
          <span v-if="type === 'checkList'">{{ $t('key1003653') }}</span>
          <span>{{ detailInfo.inventoryCheckNo }}</span>
          <!--<font id='printSkuBarCode' style='font-family: IDAutomationC128S;margin-left: 10px'>{{barCodeString}}</font>-->
          <font id="printSkuBarCode" style="font-family: IDAutomationC128S;margin-left: 10px" v-html="barCodeString"></font>
          <!--<font id='printSkuBarCode' style='font-family: IDAutomationC128S;margin-left: 10px'>{{barCodeString}}</font>-->
          <!--<span v-if='type==="pickList"' id='printSkuBarCode' style='font-family: IDAutomationC128S;margin-left: 10px'>{{detailInfo.pickingGoodsNo}}</span>-->
          <!--<span v-if='type==="checkList"' id='printSkuBarCode' style='font-family: IDAutomationC128S;margin-left: 10px'>{{detailInfo.inventoryCheckNo}}</span>-->
        </div>
        <div style="padding: 20px 0;border-bottom: 1px solid #ccc">
          <div>
            <span v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000181') }}</span><span>{{ detailInfo.warehouseName }}</span></span>
            <span v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003654') }}</span><span>{{ detailInfo.warehouseName }}</span></span>
          </div>
          <div>
            <span v-if="type === 'pickList'"><span class="textTitle ab">{{ $t('key1001076') }}</span><span>{{ detailInfo.carrierMethodNameListTxt }}</span></span>
          </div>
          <div>
            <span v-if="type === 'pickList'">
              <span class="textTitle">{{ $t('key1003655') }}</span>
              <span>{{ detailInfo.remark }}</span></span>
          </div>
          <div>
            <span v-if="type === 'pickList'" style="display: inline-block;width: 300px;white-space: nowrap"><span class="textTitle ab">{{ $t('key1003045') }}</span><span>{{ detailInfo.carrierNameListTxt }}</span></span>
          </div>
          <div>
            <span style="display: inline-block;width: 250px" v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000183') }}</span><span>{{
                detailInfo.packageGoodsType === 'MM'
                  ? $t('key1000178')
                  : $t('key1000179')
              }}</span></span>
            <span v-if="type === 'pickList'" style="display: inline-block;width: 180px"><span class="textTitle ab">{{ $t('key1000184') }}</span><span>{{ detailInfo.pickingNumber }}</span></span>
            <span style="display: inline-block;width: 250px" v-if="type === 'checkList'">
              <span class="textTitle">{{ $t('key1003656') }}</span><span>{{
                detailInfo.checkStatus === '0'
                  ? $t('key1003651')
                  : detailInfo.checkStatus === '1'
                    ? $t('key1003652')
                    : detailInfo.checkStatus === '2'
                      ? $t('key1003324')
                      : ''
              }}</span>
            </span>
            <span v-if="type === 'checkList'"><span class="textTitle ab">{{ $t('key1000837') }}</span><span>{{ detailInfo.createdTime }}</span></span>
          </div>
          <div>
            <span style="display: inline-block;width: 250px" v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000837') }}</span><span>{{ detailInfo.createdTime }}</span></span>
            <span v-if="type === 'pickList'" style="display: inline-block;width: 180px;white-space: nowrap"><span class="textTitle">{{ $t('key1000186') }}</span><span>{{ detailInfo.goodsQuantityNumber }}</span></span>
            <span style="display: inline-block;width: 250px" v-if="type === 'checkList'">
              <span style="display: inline-block;width: 250px" v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003657') }}</span><span>{{
                  detailInfo.beginDate
                    ? 'Y'
                    : 'N'
                }}</span></span>
              <span v-if="type === 'checkList'"><span class="textTitle">{{ $t('key1003658') }}</span><span>{{
                  detailInfo.blindFlag === '1'
                    ? 'Y'
                    : 'N'
                }}</span></span>
            </span>
          </div>
          <div>
            <span style="display: inline-block;width: 250px" v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000187') }}</span><span>{{
                detailInfo.pickingGoodsUser
                  ? detailInfo.pickingGoodsUser
                  : ''
              }}</span></span>
            <span v-if="type === 'pickList'"><span class="textTitle">{{ $t('key1000188') }}</span><span>{{ detailInfo.goodsSkuNumber }}</span></span>
            <span style="display: inline-block;width: 250px" v-if="type === 'checkList' && detailInfo.checkStatus !== '0'"><span class="textTitle">{{ $t('key1003659') }}</span><span>{{
                detailInfo.checkUserName
                  ? detailInfo.checkUserName
                  : ''
              }}</span></span>
          </div>
        </div>
        <!--表格-->
        <!-- <Table class="iview-table-setWidth" :columns="filterColumns"   :data="totalItem"></Table>-->
        <table width="100%" style="table-layout: fixed;">
          <thead style="background-color: #ccc">
          <tr>
            <th style="padding: 6px 0" v-show="filterColumns.includes('goodsUrl')">
              {{ $t('key1000160') }}
            </th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('warehouseBlockName')">
              {{ $t('key1000189') }}
            </th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('warehouseLocationName')">
              {{ $t('key1000190') }}
            </th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('spu')">SPU</th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('goodsSku')">SKU</th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('ymsSpu')">{{ configCharacters + 'SPU' }}</th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('ymsSku')">{{ configCharacters + 'SKU' }}</th>
            <th style="padding: 6px 0" v-if="type === 'pickList' && filterColumns.includes('expectedNumber')">
              {{ $t('key1000191') }}
            </th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('goodsCnDesc')">
              {{ $t('key1000192') }}
            </th>

            <th style="padding: 6px 0" v-show="filterColumns.includes('goodsAttributes')">
              {{ $t('key1000193') }}
            </th>
            <th style="padding: 6px 0" v-show="filterColumns.includes('receiptBatchNo') && detailInfo.checkType === '0'">
              {{ $t('key1000195') }}
            </th>
            <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('oriTotalNumber')">
              {{ $t('key1003660') }}
            </th>
            <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('checkedInventoryNumber')">
              {{ $t('key1003661') }}
            </th>
            <th style="padding: 6px 0" v-if="type === 'checkList' && filterColumns.includes('profitAndLossNumber')">
              {{ $t('key1003662') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(val, i) in totalItem" :key="i">
            <td align="center" style="white-space: nowrap" v-show="filterColumns.includes('goodsUrl')">
              <img :src="setImgPath(val.goodsUrl)" width="80" height="80" crossOrigin="anonymous" style="margin: 10px"/>
            </td>
            <td align="center" style="white-space: nowrap" v-show="filterColumns.includes('warehouseBlockName')">{{ val.warehouseBlockName }}
            </td>
            <td align="center" style="white-space: nowrap" v-show="filterColumns.includes('warehouseLocationName')">
              {{ val.warehouseLocationName }}
            </td>

            <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('spu')">
              {{ val.spu }}
            </td>
            <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('goodsSku')">
              {{ val.goodsSku }}
            </td>
            <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('ymsSpu')">
              {{ val.ymsSpu }}
            </td>
            <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('ymsSku')">
              {{ val.ymsSku }}
            </td>
            <td align="center" v-if="type === 'pickList' && filterColumns.includes('expectedNumber')">
              {{ val.expectedNumber }}
            </td>

            <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsCnDesc')">
              {{ val.goodsCnDesc }}
            </td>
            <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsAttributes')">
              {{
                val.goodsAttributes
                  ? val.goodsAttributes
                  : ''
              }}
            </td>
            <td align="left" style="word-break: break-all;" v-show="filterColumns.includes('goodsEnDesc')">
              {{ val.goodsEnDesc }}
            </td>
            <td align="center" style="word-break: break-all;" v-show="filterColumns.includes('receiptBatchNo') && detailInfo.checkType === '0'">
              {{ val.receiptBatchNo }}
            </td>
            <td align="center" v-if="type === 'checkList' && filterColumns.includes('oriTotalNumber')">
              {{
                detailInfo.blindFlag === '1'
                  ? '*'
                  : val.oriTotalNumber
              }}
            </td>
            <td align="center" v-if="type === 'checkList' && filterColumns.includes('checkedInventoryNumber')">{{ val.checkedInventoryNumber }}
            </td>
            <td align="center" v-if="type === 'checkList' && filterColumns.includes('profitAndLossNumber')">{{ val.profitAndLossNumber }}
            </td>
          </tr>
          </tbody>
        </table>
        <div class="clear">
          <div class="fr" style="margin-top: 50px">
            <span style="margin-right: 100px;font-size: 14px">{{ $t('key1002418') }} {{ $t('key1002419') }}</span>
            <span style="margin-right: 100px;font-size: 14px">{{ $t('key1002420') }} {{ $t('key1002421') }}</span>
          </div>
        </div>
      </div>
      <div style="position: absolute;right: -125px;top:0" class="print-none">
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="print">{{ $t('key1000825') }} </Button>
        </div>
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="download">{{ $t('key1000366') }} </Button>
        </div>
        <div style="margin-bottom: 10px">
          <Button type="primary" @click="cancelPrint">{{ $t('key1002422') }} </Button>
        </div>
        <div style="height: 35px;position: relative">
          <local-filter-columns :columns="columns" :filterName="'printPickList'" @setTableColumns="setTableColumns"></local-filter-columns>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

// 打印盘点单与拣货单共用模块
import api from '@/api/';
import common from '@/components/mixin/common_mixin';
import filterColumns from '@/components/localComponents/filterColumns/filterColumns';
import pdf from '@/utils/pdf';
import {setConfigCharacters} from "@/utils/common";

export default {
  // type === 'checkList'为盘点单
  mixins: [common],
  data() {
    let v = this;
    let columns1 = [
      {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000160'),
        key: 'goodsUrl'
      }, {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000189'),
        key: 'warehouseBlockName',
        filterHide: true
      }, {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000190'),
        key: 'warehouseLocationName',
        filterHide: true
      }, {
        title: 'SPU',
        key: 'spu'
      }, {
        title: 'SKU',
        key: 'goodsSku'
      }, {
        title: `${setConfigCharacters}SPU`,
        key: 'ymsSpu'
      }, {
        title: `${setConfigCharacters}SKU`,
        key: 'ymsSku'
      }, {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000192'),
        key: 'goodsCnDesc'
      }, {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000193'),
        key: 'goodsAttributes'
      }, {
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000195'),
        key: 'receiptBatchNo',
        filterHide: true
      }
    ];
    let columns2 = JSON.parse(JSON.stringify(columns1));
    let columns3 = JSON.parse(JSON.stringify(columns1));
    columns2.splice(2, 0, {
      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000191'),
      key: 'expectedNumber'
    });
    columns3.push({
      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003660'),
      key: 'oriTotalNumber',
      render(h, params) {
        let data = v.detailInfo.blindFlag === '1'
          ? '*'
          : params.row.oriTotalNumber;
        return h('span', data);
      }
    }, {
      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003661'),
      key: 'checkedInventoryNumber'
    }, {
      title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003662'),
      key: 'profitAndLossNumber'
    });
    return {
      placeholderUrl: require('../../../../../../public/static/images/placeholder.jpg'),
      localeUrl: window.location.origin
        ? window.location.origin
        : window.location.protocol + '//' + window.location.hostname + (window.location.port
        ? ':' + window.location.port
        : ''), // 兼容性写法
      totalItem: '',
      detailInfo: '',
      barCodeString: '',
      type: '',
      columns: [],
      filterColumns: [],
      columns2: columns2,
      columns3: columns3
    };
  },
  computed: {
    prefixUrl() {
      return localStorage.getItem('imgUrlPrefix');
    }
  },
  created() {
    let obj = this.$route.query.data;
    this.type = this.$route.query.type;
    this.where = this.$route.query.where;
    this.goodsSkuOrGoodsCnDesc = this.$route.query.goodsSkuOrGoodsCnDesc;
    if (typeof obj === 'object') {
      window.localStorage.setItem('paramsDtl', JSON.stringify(obj));
    } else if (typeof JSON.parse(window.localStorage.getItem('paramsDtl')) === 'object' && JSON.parse(window.localStorage.getItem('paramsDtl')) !== null) {
      obj = JSON.parse(window.localStorage.getItem('paramsDtl'));
    }
    let paramsObj = null;
    if (this.type === 'pickList') {
      paramsObj = {
        isPage: '1',
        status: '1',
        pickingGoodsNos: obj
          ? [obj]
          : null
      };
      this.columns = this.columns2;
    } else if (this.type === 'checkList') {
      paramsObj = {
        isPage: '1',
        status: '1',
        goodsSkuOrGoodsCnDesc: this.goodsSkuOrGoodsCnDesc,
        pickingGoodsNos: obj
          ? [obj]
          : null
      };
      this.columns = this.columns3;
    }
    // 盘点单需要的参数
    if (this.type === 'checkList') {
      if (typeof obj === 'object') {
        paramsObj.inventoryCheckNo = obj.inventoryCheckNo
          ? [obj.inventoryCheckNo]
          : null;
      } else if (typeof obj === 'string') {
        paramsObj.inventoryCheckNo = obj;
      }
      delete paramsObj.pickingGoodsNos;
    }
    this.axios.post(this.type === 'checkList'
      ? api.get_scanDetailData
      : api.post_wmsPickingGoods_detail, paramsObj).then(res => {
      if (res.data.code === 0) {
        console.log(res);
        if (this.type === 'checkList') {
          this.detailInfo = this.processObjTime(res.data.datas, ['createdTime']);
        } else {
          this.detailInfo = this.processObjTime(res.data.datas[0], ['createdTime']);
          if (this.detailInfo.carrierMethodNameList) {
            if (this.detailInfo.carrierMethodNameList.length > 1) {
              this.detailInfo.carrierMethodNameListTxt = alias1bddc4d174174d47a6dfb0437dace856.t('key1000199') + this.detailInfo.carrierMethodNameList[0] + alias1bddc4d174174d47a6dfb0437dace856.t('key1000197');
            } else {
              this.detailInfo.carrierMethodNameListTxt = this.detailInfo.carrierMethodNameList[0];
            }
          }
          if (this.detailInfo.carrierNameList) {
            if (this.detailInfo.carrierNameList.length > 1) {
              this.detailInfo.carrierNameListTxt = alias1bddc4d174174d47a6dfb0437dace856.t('key1000199') + '，' + this.detailInfo.carrierNameList[0] + alias1bddc4d174174d47a6dfb0437dace856.t('key1000197');
            } else {
              this.detailInfo.carrierNameListTxt = this.detailInfo.carrierNameList[0];
            }
          }
        }
        if (this.type === 'checkList') {
          this.totalItem = res.data.datas.inventoryCheckDetailResultPage.list;
        } else {
          if (paramsObj.status === '0') {
            this.totalItem = res.data.datas[0].queryByPickingResultPage.list;
          } else {
            this.totalItem = res.data.datas[0].queryByGoodsDetailResultPage.list;
          }
        }
      }
    });
    // 获取条码
    let pickingGoodsNo = typeof obj === 'object'
      ? obj.pickingGoodsNo
        ? [obj.pickingGoodsNo]
        : null
      : obj
        ? [obj]
        : null;
    this.axios.post(api.getBarCode, pickingGoodsNo).then(res => {
      if (res.data.code === 0) {
        this.barCodeString = res.data.datas[0][pickingGoodsNo];
      }
    });
  },
  destroyed() {
    localStorage.removeItem('paramsDtl');
  },
  methods: {
    setTableColumns(data) {
      this.filterColumns = data.map(i => i.key);
    },
    print() {
      let v = this;
      let obj = this.$route.query.data;
      this.changePrintStatus(obj);
      window.print();
    },
    cancelPrint() {
      window.location.href = '#/wms/pickList';
    },
    changePrintStatus(obj) {
      let paramsObj = {
        pickingGoodsNos: [obj]
      };
      this.axios.post(api.post_wmsPickingGoods_print, paramsObj).then(res => {
        if (res.data.code === 0) {
          this.$Message.success(res.data.message);
        }
      });
    },
    download() {
      pdf('#printBox', 'web');
    }
  },
  components: {
    localFilterColumns: filterColumns
  }
};
</script>
<style>
.iview-table-setWidth .ivu-table:before {
  height: 0
}

.iview-table-setWidth .ivu-table:after {
  height: 0
}

.print-mb100 {
  margin-bottom: 100px;
}

.print-n-box {
  width: 1000px;
  background-color: #fff;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.print-box {
  background-color: #ccc;
  padding: 34px 56px;
  font-size: 16px;
}

#printBox {
  padding: 20px;
}

.iview-table-setWidth .ivu-table-overflowX {
  overflow: hidden
}

.print-n-box .ivu-table-wrapper {
  border: 1px solid #999;
  border-bottom: 0;
}

.print-n-box .ivu-table td, .print-n-box .ivu-table th {
  border-bottom: 1px solid #999;
}

@page {
  margin: 0;
  padding: 0;
}

@media print {
  html, body, #app {
    min-width: auto;
  }

  * {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  .iview-table-setWidth .ivu-table-header table {
    width: 100% !important;
    table-layout: initial
  }

  .iview-table-setWidth .ivu-table-body table {
    width: 100% !important;
    table-layout: initial
  }

  .iview-table-setWidth .ivu-table:before {
    height: 0
  }

  .iview-table-setWidth .ivu-table:after {
    height: 0
  }

  .print-n-box .ivu-table-wrapper {
    border: 1px solid #999;
    border-bottom: 0;
  }

  .print-n-box .ivu-table td, .print-n-box .ivu-table th {
    border-bottom: 1px solid #999;
  }

  #printBox {
    padding: 0 !important;
  }

  .print-n-box {
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    padding: 0 16px;
  }

  .print-box {
    width: 100%;
    font-size: 16px;
    background-color: #ccc;
    padding: 0
  }

  .print-mb100 {
    margin-top: 5pt
  }

  .print-none {
    display: none;
  }
}

.textTitle {
  font-size: 16px;
  width: 150px;
}

* {
  margin: 0;
  padding: 0;
}
</style>
<style scoped>
table {
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top: 1px solid #9a9a9a;
  border-left: 1px solid #9a9a9a;
}

.table-border {
  border: 1px solid #666;
  border-bottom: 0;
  border-right: 0;
}

table td,
table th {
  border-bottom: 1px solid #9a9a9a;
  border-right: 1px solid #9a9a9a;
}
</style>
